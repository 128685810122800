body {
  margin: 0px;
  padding-top: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #35503A;
  color: #E6DBCA;
  font-family: 'Montserrat', sans-serif;
}

div{
  display: flex;
  flex-direction: column;
}

.g-container{
  margin: 0 auto;
  width: 1300px;
  padding: 0px 30px;
  flex-direction: column;
  position: relative;
}

.g-wrapperScreens{
  width: 100%;
  flex-direction: column;
}

* { 
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
   box-sizing: border-box; 
}

p{
  margin: 0;
}

.carousel .slider-wrapper{
  width: 50%;
}

.carousel li.thumb{
  width: 90px;
  height: inherit;
  border: 3px solid #CFB594;
}

div.leaflet-bottom.leaflet-right{
  display: none;
}

section{
  display: flex;
}

@media only screen and (max-width: 1300px) {
  .g-container{
    width: 100%;
  }
}