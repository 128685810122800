.price{
    margin-top: 80px;
    position: relative;

    &_img{
        position: absolute;
        left: 20%;
        top: -100px;
    }

    &__wrapper{

        h2{
            font-size: 28px;
            line-height: 34px;
            color: #E6DBCA;
            font-weight: 600;
            text-align: center;
        }

        &__blocks{
            justify-content: space-between;
            flex-direction: row;

            &__item{
                width: 30%;
                height: 450px;
                border: 1px solid #E6DBCA;
                box-shadow: 2px 2px 10px .1px #d0cab8;
                justify-content: space-between;
                padding-bottom: 44px;
                margin-top: 44px;

                &__type{
                    width: 100%;
                    height: 100px;
                    background-color: #424027;
                    align-items: center;
                    justify-content: center;

                    &_middle{
                        background-color: #463A20;
                    }

                    &_high{
                        background-color: #4A351A;
                    }
                    
                    &_title{
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 29px;
                        color: #E6DBCA;
                    }
                }
            }

            &__middle{
                margin: 0px 40px;
                font-weight: 400;
                font-size: 18px;
                line-height: 29px;
                color: #E6DBCA;
                align-items: center;

                &_text{
                    margin: 8px 0px;

                    span{
                        font-weight: 600;
                    }
                }
            }

            &_price{
                font-weight: 500;
                font-size: 34px;
                line-height: 49px;
                color: #E6DBCA;
                text-align: center;

                &_low{
                    font-size: 24px;
                }

                &_mobile{
                    display: none;
                }
            }
        }

        &_more{

            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            color: #E6DBCA;
            text-align: center;
            margin-top: 40px;

        }

        &__service{
            background: #4A351A;
            width: 100%;
            height: 80px;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 24px;
            align-items: center;
            padding: 0px 24px;
            font-weight: 500;
            font-size: 18px;
            line-height: 29px;
            text-align: center;
            color: #E6DBCA;
        }
    }
}

@media only screen and (max-width: 1300px) {

    .price{

        margin-top: 44px;
    
        &__wrapper{
    
            &__blocks{
    
                &__item{
                    
                    height: 350px;
                    padding-bottom: 32px;
                    margin-top: 32px;
    
                    &__type{
                        height: 80px;
    
                        &_middle{
                            background-color: #463A20;
                        }
    
                        &_high{
                            background-color: #4A351A;
                        }
                        
                        &_title{
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                }
    
                &__middle{
                    margin: 0px 4px;
                    font-weight: 400;
                    font-size: 16px;
    
                    &_text{
                        margin: 0px 0px;
    
                        span{
                            font-weight: 400;
                        }
                    }
                }
    
                &_price{
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;
                    color: #E6DBCA;
                    text-align: center;
    
                    &_low{
                        font-size: 18px;
                    }

                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {

    .price{
        margin-top: 64px;
        position: relative;
    
        &_img{
            position: absolute;
            left: 20%;
            top: -100px;
        }
    
        &__wrapper{
    
            &__blocks{
                flex-direction: column;
    
                &__item{
                    width: 100%;
                    height: 300px;
                    margin-top: 24px;
                    justify-content: space-between;
    
                    &__type{
                        width: 100%;
                        height: 100px;
                        justify-content: center;
    
                        &_middle{
                            background-color: #463A20;
                        }
    
                        &_high{
                            background-color: #4A351A;
                        }
                        
                        &_title{
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 29px;
                            color: #E6DBCA;
                        }
                    }
                }
    
                &__middle{
                    margin: 0px 0px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 29px;
                    color: #E6DBCA;
                    align-items: center;
                    margin-top: 0px;
    
                    &_text{
                        margin: 8px 0px;
    
                        span{
                            font-weight: 600;
                        }
                    }
                }
    
                &_price{

                    display: none;
    
                    &_low{
                        font-size: 24px;
                    }

                    &_mobile{
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 49px;
                        color: #E6DBCA;
                        text-align: center;
                        display: flex;
                        justify-content: center;
        
                        &_low{
                            font-size: 24px;
                        }
                    }
                }

            }
    
            &_more{
    
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
            }
    
            &__service{
                width: 100%;
                height: 80px;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 24px;
                align-items: center;
                padding: 0px 24px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}