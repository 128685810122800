.header{

    width: 100%;
    margin-top: 28px;
    z-index: 15;
    position: absolute;
    display: flex;
    
    &__wrapper{

        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // width: 100%;

        img{
            width: 202px;
            height: 53px;
        }
    
        &__menu{
    
            &__list{
                display: flex;
                list-style-type: none;
                margin: 0px;
                padding: 0px;
    
                &_item{
                    margin: 0px 24px;
                    color: #FFFFFF;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    position: relative;
                    cursor: pointer;
                    color: #fff;
                    transition: .5s all;
                }
    
                &_item:last-child{
                    margin: 0px 0px 0px 24px;
                }
    
                &_item:hover::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 2px;
                    background-color: #fff;
                    bottom: -4px;
                    left: 0px;
                }
    
                &_item:active{
                    color: #99856C;
                }
            }
        }
    }
}

@media only screen and (max-width: 1300px) {

    .header{

        &__wrapper{
    
            img{
                width: 140px;
            }
        
            &__menu{

                &__list{
        
                    &_item{
                        margin: 0px 16px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 26px;
                    }
        
                    &_item:last-child{
                        margin: 0px 0px 0px 16px;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 767px) {
    .header{
        display: none;
    }
}