.map{
    margin-top: 64px;

    &__wrapper{
        flex-direction: row;

        &__info{
            width: 33%;
            height: 500px;
            background-color: #E6DBCA;

            &_title{
                text-align: center;
                font-weight: 600;
                font-size: 28px;
                line-height: 34px;
                color: #99856C;
                margin-top: 40px;
            }

            &__data{
                flex-direction: column;
                margin-top: 48px;
                margin-left: 32px;

                &__item{
                    flex-direction: row;
                    margin-top: 16px;
                    align-items: center;

                    &_img{
                        width: 32px;
                        height: 32px;
                    }

                    &_title{
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                        color: #99856C;
                        margin-left: 16px;
                    }
                }
            }

        }

        &__map{
            width: 67%;
            height: 500px;
        }

    }
}

@media only screen and (max-width: 1300px) {
    .map{
        margin-top: 64px;
    
        &__wrapper{
    
            &__info{
                width: 33%;
                height: 500px;
    
                &_title{
                    font-size: 24px;
                }
    
                &__data{
    
                    &__item{
    
                        &_img{
                            width: 24px;
                            height: 24px;
                        }
    
                        &_title{
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 22px;
                            color: #99856C;
                            margin-left: 16px;
                        }
                    }
                }
            }
    
            &__map{
                width: 67%;
            }
    
        }
    }
}

@media only screen and (max-width: 992px) {
    .map{
        &__wrapper{
    
            &__info{
                width: 38%;
                height: 500px;
    
                &_title{
                    font-size: 20px;
                }
    
                &__data{
    
                    &__item{
    
                        &_title{
                            font-weight: 500;
                            font-size: 14px;
                        }
                    }
                }
            }
    
            &__map{
                width: 62%;
            }
    
        }
    }
}

@media only screen and (max-width: 767px) {
    .map{
        margin-top: 64px;
    
        &__wrapper{
            flex-direction: column-reverse;
    
            &__info{
                width: 100%;
                height: auto;
                padding-bottom: 24px;
    
                &_title{
                    text-align: center;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 24px;
                    color: #99856C;
                    margin-top: 40px;
                }
    
                &__data{
                    flex-direction: column;
                    margin-top: 24px;
                    margin-left: 32px;
    
                    &__item{
                        flex-direction: row;
                        margin-top: 16px;
                        align-items: center;
    
                        &_img{
                            width: 32px;
                            height: 32px;
                        }
    
                        &_title{
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 22px;
                            color: #99856C;
                            margin-left: 16px;
                        }
                    }
                }
    
            }
    
            &__map{
                width: 100%;
                height: 500px;
            }
    
        }
    }
    
}